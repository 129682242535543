<template>
  <div class="fac-wrapper">
    <div class="icon">
      <i class="far fa-check"></i>
    </div>
    {{ data }}
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
div.fac-wrapper {
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
div {
  padding-left: 10px;
  min-height: 20px;
  min-width: 20px;
  font-size: 1rem;
  display: flex;
  position: relative;
  float: left;
  margin: 2px 1px;
}
div.icon {
  margin: 2px 0;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0;
  content: "";
  color: #4ba0ff;
}
.fab,
.far {
  font-weight: 900;
}
</style>
